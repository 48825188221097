// @import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@import "./variables.scss";
@import "./keyframes.scss";
@import "./misc.scss";

@import 'bootstrap/scss/bootstrap';
@import "./theme.scss";


/* Modal classes */
.direct-buy-modal{
  max-width: 340px;
  margin: auto;
}

/* Avoid lag */
.draggable {
  transition: 0s all!important;
}
