@media (max-width: 991px) {
  .container {
    max-width: none;
  }
}

.fancy-underline {
  position: relative;
  padding-bottom: 5px;
  &::after {
    position: absolute;
    left: 0;
    top: 60%;

    border-radius: 5px;
    content: " ";
    display: block;
    background-color: rgba(var(--bs-primary), 0.3);
    height: 10px;
    width: 100%;
  }
}

/* Forms */
.btn-file {
  position: relative;
  cursor: pointer;
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.page {
  position: relative;
  z-index: 2;
}
