// suass 5.3.2
@import "bootstrap/scss/functions";

$theme: "suass" !default;
// Theme functional vars
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
$sidebarWidth: 250px;
$sidebarWidthCompact: 55px;
$mainNavBottomHeight: 60px;
$sidebarCompactBreakpoint: map-get($grid-breakpoints, 'lg');
$sidebarBottomBreakpoint: map-get($grid-breakpoints, 'sm');
//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #3459e6 !default;
$indigo:  #4f46e5 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #da292e !default;
$orange:  #f8765f !default;
$yellow:  #f4bd61 !default;
$green:   #2fb380 !default;
$teal:    #20c997 !default;
$cyan:    #287bb5 !default;



$winnerGreen:   #89ffc2 !default;
$primary:       $indigo !default;
$tertiary:      $gray-100!default;
$secondary:     $gray-300 !default;
$success:       $teal !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$custom-theme-colors: (
  "teal": $teal,
  "cyan": $cyan,
  "indigo": $indigo,
  "secondary": $secondary,
  "tertiary": $gray-300
);




$min-contrast-ratio:   1.65 !default;

$enable-shadows:              true !default;

// Body

$body-color:                $gray-700 !default;
$headings-color:              var(--bs-primary-color) !default;

// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:      'Public Sans'!default;//Barlow !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;

// Borders
$border-radius: 5px !default;
$border-color: $secondary !default;
$border-color-translucent: $secondary !default;

// Shadows
$box-shadow:                   0 0.5rem 1rem rgba(0, 0, 0, 0.15) !default; // 0px 18px 36px -18px rgba(0,0,0,0.1),0px 30px 45px -30px rgba(50,50,93,0.25)!default;
$box-shadow-sm:               0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !default; // 0px 18px 36px -18px rgba(0,0,0,0.1),0px 30px 45px -30px rgba(50,50,93,0.25)!default;
$box-shadow-lg:               0 1rem 3rem rgba(0, 0, 0, 0.175) !default;

// Components
// Tables

$table-cell-padding-y:        .4rem !default;
$table-cell-padding-x:        1rem !default;
$table-cell-padding-y-sm:     .25rem !default;
$table-cell-padding-x-sm:     .25rem !default;

$table-th-font-weight:        500 !default;
// $table-border-color:          $gray-300;
// Buttons + Forms

$input-btn-padding-y:         .5rem !default;
$input-btn-padding-x:         .5rem !default;
$input-btn-font-size:         $font-size-sm !default;
$input-line-height: 1;

// Buttons

$btn-font-weight:             500 !default;
$btn-box-shadow:              $box-shadow-sm !default;
$btn-focus-box-shadow:        $box-shadow-sm !default;
$btn-active-box-shadow:       $box-shadow-sm !default;

// Forms

$form-label-font-weight:                500 !default;

$input-box-shadow:                      $box-shadow-sm !default;

// Navs

$nav-link-color:                    $body-color !default;
$nav-link-hover-color:              $body-color !default;

$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-active-color:        $primary !default;

// Navbar

$navbar-padding-y:                  .85rem !default;

$navbar-nav-link-padding-x:         .75rem !default;

// Dropdowns

$dropdown-font-size:                $font-size-sm !default;
$dropdown-border-color:             $gray-300 !default;
$dropdown-divider-bg:               $gray-200 !default;

$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $primary !default;

$dropdown-item-padding-y:           .5rem !default;
$dropdown-item-padding-x:           1rem !default;

// Pagination

$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              1rem !default;

$pagination-color:                  var(--bs-primary-bg) !default;

$pagination-focus-color:            $pagination-color !default;

$pagination-hover-color:            $pagination-color !default;
$pagination-hover-bg:               var(--bs-secondary-bg) !default;

$pagination-disabled-color:         var(--bs-tertiary-color) !default;
$pagination-disabled-bg:            var(--bs-tertiary-bg) !default;

// Cards
$card-spacer-x:                     1rem !default;
$card-cap-padding-y:                0.75rem !default;
$card-cap-padding-x:                1rem !default;
$card-border-width:                 2px !default;

// Toasts

$toast-header-color:                $headings-color !default;

// Modals
$modal-footer-border-width: 0 !default;
$modal-header-border-width: 0 !default;
// List group

$list-group-item-padding-y:         1rem !default;
$list-group-item-padding-x:         1rem !default;

// Breadcrumbs

$breadcrumb-padding-x:              1rem !default;

$breadcrumb-divider:                quote(">") !default;



// Dark mode
$body-bg-dark: rgb(15, 23, 42);
$body-secondary-bg-dark: #1f192b;
// $body-color-dark: #dee2e6;
$card-border-width-dark: 2px;
$border-color-dark: #352644;
$border-color-translucent-dark: #352644;

// Variable overrides
@import "bootstrap/scss/variables";
$theme-colors: map-merge($theme-colors, $custom-theme-colors);
