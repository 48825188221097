body,
html {
  height: 100%;
}
// Cursors
.pointer {
  cursor: pointer;
}

// Buttons
.btn {
  transition: 0.25s all;
  text-shadow: 1px 1px rgba($black, 0.05);
}
.btn-secondary,
.btn-light,
.btn-outline-secondary,
.btn-outline-light {
  color: $gray-900;

  &:disabled,
  &.disabled {
    border: 1px solid shade-color($secondary, 10%);
  }
}

.btn-secondary {
  background-color: $white;
}

.btn-secondary,
.btn-outline-secondary {
  border-color: shade-color($secondary, 10%);

  &:hover,
  &:active {
    background-color: shade-color($secondary, 10%);
    border-color: shade-color($secondary, 10%);
  }
}

.btn-light,
.btn-outline-light {
  border-color: shade-color($light, 10%);

  &:hover,
  &:active {
    background-color: shade-color($light, 10%);
    border-color: shade-color($light, 10%);
  }
}
// Tables
table tr.table-active td {
}
.table-bottom-spacing {
  padding-bottom: 10rem;
}

// Navs

.nav-tabs {
  font-weight: 500;

  .nav-link {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    border-width: 0 0 1px;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    box-shadow: inset 0 -3px 0 $primary;
  }

  .nav-link,
  .nav-item {
    &:hover {
      color: $primary;
    }
  }
}

.nav-pills {
  font-weight: 500;
}

.pagination {
  font-size: $font-size-sm;
  font-weight: 500;

  .page-link {
    box-shadow: $box-shadow;
  }
}

.breadcrumb {
  font-size: $font-size-sm;
  font-weight: 500;
  border: 1px solid var(--bs-secondary-bg);
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  &-item {
    padding: 1rem 0.5rem 1rem 0;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 1rem;
}

// Forms
.form-label {
  font-size: 90%;
}
.form-select {
}

// Dropdown
.dropdown-nocaret {
  .dropdown-toggle::after {
    display: none;
  }
}

/* Cards */
.card {
  .card-header {
    border-bottom-width: 0 !important;
  }
  .card-footer {
    border-top-width: 0 !important;
  }
}
@media (max-width: map-get($grid-breakpoints, "md")) {
  .card {
    .card-header {
      border-bottom-width: 0 !important;
    }
    .card-body {
      padding: 1rem !important;
    }
  }
}
